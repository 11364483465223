<template>
    <ol :data-level="level" class="navigation-node">
        <li v-for="route in nodes">
            <component v-if="route.meta.componentBefore" :is="route.meta.componentBefore"/>
            <router-link class="navigation-node__link" :to="getNodeToValue(route)" @click="$emit('navigation')">
                <SvgIcon v-if="route.meta.iconBefore" :src="route.meta.iconBefore"/>
                <span>{{ route.meta.translate ? $t(route.meta.label) : route.meta.label }}</span>
                <SvgIcon v-if="route.meta.iconAfter" :src="route.meta.iconAfter"/>
            </router-link>
            <NavigationNode v-if="route.children" class="navigation-node__children" :routes="route.children" :level="level + 1"/>
            <component v-if="route.meta.componentAfter" :is="route.meta.componentAfter"/>
        </li>
    </ol>
</template>

<script>
import SvgIcon from "./SvgIcon.vue";

export default {
    components: {SvgIcon},
    emits: ['navigation'],

    name: 'NavigationNode',

    props: {
        routes: {
            required: true,
            type: Array
        },

        level: {
            required: false,
            type: Number,
            default: 0
        }
    },

    methods: {
        filterHiddenRoutes(routes) {
            return _.filter(routes, (route) => {
                if(typeof route.meta.hidden === 'function' ? route.meta.hidden(this.$root) : route.meta.hidden) {
                    return false;
                }

                let options = {
                    route: {
                        to: this.$router.resolve(route),
                    }
                };

                if(route.meta.permissions?.length && !this.$user.hasPermission(route.meta.permissions, options)) {
                    return false;
                }

                if(route.meta.roles?.length && !this.$user.hasRole(route.meta.roles, options)) {
                    return false;
                }

                return true;
            });
        },


        getNodeToValue(node) {
            let obj = {};

            obj[node.name ? 'name' : 'path'] = node.name ?? node.path;

            return obj;
        }
    },

    computed: {
        nodes() {
            return this.filterHiddenRoutes(this.routes);
        }
    }
}
</script>
