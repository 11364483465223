class RoleOrPermission {
    /**
     * @param {string} name
     */
    constructor(name) {
        this.name = name;
    }

    toString() {
        return this.name;
    }
}

export class Permission extends RoleOrPermission {}

export class Role extends RoleOrPermission {}
