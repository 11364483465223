<template>
    <div class="frame-common" :class="getMainFrameClasses">
        <div class="frame-common__header">
            <ul class="frame-common__header__locales">
                <li v-for="(locale, key) in getAvailableLocales" @click="setLocaleEvent(key)">{{locale}}</li>
            </ul>
        </div>
        <div class="frame-common__content">
            <div class="frame-common__content__navigation">
                <Navigation :class="getNavigationMenuClasses"/>
            </div>
            <div class="frame-common__content__view">
                <Scrollable>
                    <router-view class="page" v-if="!$user.isLoggedIn()" v-slot="{ Component }">
                        <Transition>
                            <component :is="Component" :class="getContentViewClasses" class="page"/>
                        </Transition>
                    </router-view>
                    <router-view class="page" v-else :class="getContentViewClasses"></router-view>
                </Scrollable>
            </div>
        </div>
    </div>
</template>

<script>
import Navigation from "../../components/Navigation.vue";
import Button from "../../components/buttons/Button.vue";
import {Scrollable} from "@jensarray/library";

export default {
    components: {Button, Navigation, Scrollable},

    data() {return {
        navigationMenuOpened: false,
    };},

    methods: {
        setLocaleEvent(key) {
            this.$configuration.setLocale(key);
        },

        getThemeName() {
            // Or attach a select menu for the theming and return the proper value from that.
            return "-styling-theme-default";
        },

        toggleNavigationMenuEvent() {
            this.navigationMenuOpened ?
                this.closeNavigationMenu() :
                this.openNavigationMenu();
        },

        openNavigationMenu() {
            this.navigationMenuOpened = true;
        },

        closeNavigationMenu() {
            this.navigationMenuOpened = false;
        }
    },

    computed: {
        getAvailableLocales() {
            return this.$configuration.get('vueJs.localization.allowed');
        },

        getMainFrameClasses() {
            return [
                this.$user.isLoggedIn() ? '-user' : '-guest',
                this.getThemeName()
            ];
        },

        getContentViewClasses() {
            return this.getMainFrameClasses; // Providing the same information for as helper for styling
        },

        getNavigationMenuClasses() {
            return {
                '-opened': this.navigationMenuOpened,
                '-closed': !this.navigationMenuOpened
            };
        }
    }
}
</script>

<style lang="scss">
@import "@scss/pages/common/Frame.scss";
</style>
