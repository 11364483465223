<template>
    <nav class="navigation" role="navigation">
        <NavigationNode class="navigation__list" :routes="routes"/>
    </nav>
</template>

<script>
import NavigationNode from "./NavigationNode.vue";

export default {
    components: {NavigationNode},
    emits: ['navigation'],

    computed: {
        routes() {
            return this.$router.options.routes;
        }
    }
}
</script>

<style lang="scss">
@import "@scss/components/Navigation.scss";
</style>
