import {RedirectRoute, Route} from "../classes/RouteTypes";

export default [
    new RedirectRoute({path: '/', name: 'index', redirect: {name: 'homepage'}}),

    new Route({path: '/welcome', name: 'homepage', label: 'navigation.homepage',
        component: () => import('../pages/guest/homepage/Frame'),
        iconBefore: new URL('@media/images/vector/law.svg', import.meta.url).href
    }),

    new Route({path: '/impressum', name: 'impressum', label: 'navigation.impressum',
        component: () => import('../pages/guest/impressum/Frame'),
        iconBefore: new URL('@media/images/vector/scale.svg', import.meta.url).href
    }),
];
