import { reactive } from 'vue';
export default class MouseObserver {
    constructor() {
        this.mouse = reactive({
            // position relative to the visible content window
            clientX: 0,
            clientY: 0,
            // position relative to the screen
            screenX: 0,
            screenY: 0,
            // relative distance to the last positions
            movementX: 0,
            movementY: 0,
        });
    }

    install(app, options) {
        addEventListener('mousemove', this.mouseMoveEvent.bind(this));

        let $this = this;
        const exposed = {
            get clientX() {
                return $this.mouse.clientX;
            },

            get clientY() {
                return $this.mouse.clientY;
            },

            get screenX() {
                return $this.mouse.screenX;
            },

            get screenY() {
                return $this.mouse.screenY;
            },

            get movementX() {
                return $this.mouse.movementX;
            },

            get movementY() {
                return $this.mouse.movementY;
            }
        };

        /**
         * Example:
         * this.$root.$formatter
         */
        app.config.globalProperties.$mouseObserver = exposed;

        /**
         * Example:
         * import { inject } from 'vue';
         *
         * const Formatter = inject('MouseObserver')
         */
        app.provide('MouseObserver', exposed);
    }

    mouseMoveEvent(event) {
        this.mouse.clientX = event.clientX;
        this.mouse.clientY = event.clientY;

        this.mouse.screenX = event.screenX;
        this.mouse.screenY = event.screenY;

        this.mouse.movementX = event.movementX;
        this.mouse.movementY = event.movementY;
    }
}
