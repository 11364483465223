import Cookies from 'js-cookie';

export default class {
    #configuration = Object.freeze(_.cloneDeep(window.frontendConfiguration));
    #I18n = null;

    constructor(I18n) {
        this.#I18n = I18n;
    }

    install(app, options) {
        const exposed = {
            get: this.get.bind(this),
            resolveAsUrl: this.resolveAsUrl.bind(this),
            setLocale: this.setLocale.bind(this),
            getLocale: this.getLocale.bind(this)
        };

        /**
         * Example:
         * this.$root.$configuration
         */
        app.config.globalProperties.$configuration = exposed;

        /**
         * Example:
         * import { inject } from 'vue';
         *
         * const ConfigurationProvider = inject('ConfigurationProvider')
         */
        app.provide('ConfigurationProvider', exposed);
    }

    /**
     * @param {string} key Dot separated keychain
     * @param {any} def Default
     */
    get(key, def = undefined) {
        return _.get(this.#configuration, key, def);
    }

    /**
     * @deprecated
     * @param key
     * @param options
     */
    resolveAsUrl(key, options) {
        console.warn('resolveAsUrl is deprecated. Use config.UrlParams');

        let value = this.get(key, null);

        if(!value) {
            return value;
        }

        let target = options?.params ?? {};
        for(let index in target) {
            let param = target[index];
            let replaceKey = `:${index}`;

            value = value.replace(replaceKey, typeof param === 'function' ? param(param(options), index) : param);
        }

        return value;
    }

    getLocale() {
        return this.#I18n.mode === 'legacy' ? this.#I18n.global.locale : this.#I18n.global.locale.value;
    }

    /**
     *
     * @param {string} locale
     */
    async setLocale(locale) {
        if(!this.get('vueJs.localization.allowed').hasOwnProperty(locale)) {
            console.error(`Localization key "${locale}" is not allowed!`);
            return;
        }

        if(!this.#I18n.global.availableLocales.includes(locale)) {
            let prefix = this.#configuration.user ? 'authenticated' : 'guest';
            let Module = await import(`../localization/${prefix}/${locale}.js`);

            this.#I18n.global.setLocaleMessage(locale, Module.default);
        }

        if (this.#I18n.mode === 'legacy') {
            this.#I18n.global.locale = locale;
        }
        else {
            this.#I18n.global.locale.value = locale;
        }

        Cookies.set(this.get('vueJs.localization.cookieName') ?? 'Accept-Language', locale, {
            expires: 400
        });
        document.documentElement.setAttribute('lang', locale);
    }
};
